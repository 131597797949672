import { Link } from "gatsby";
import styled from "styled-components";
import { Color } from "../../layout/GlobalStyles/Color";
import { Viewport } from "../../layout/GlobalStyles/Viewport";

interface IStyleProps {
    readonly isDarkTheme?: boolean;
}

export const Root = styled("div")<IStyleProps>`
    background-color: ${({ isDarkTheme }) => (isDarkTheme ? Color.Neutral100 : Color.Neutral10)};
    padding-top: 64px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    color: ${({ isDarkTheme }) => (isDarkTheme ? Color.White100 : Color.Neutral100)};
`;

export const Container = styled.div`
    margin: 0 auto;
`;

export const Wrapper = styled.div`
    display: flex;
    width: 80vw;
    max-width: 1500px;
    justify-content: space-between;

    @media screen and (max-width: ${Viewport.Large}px) {
        flex-direction: column;
        max-width: 100%;
    }
`;

export const SectionWrapper = styled.div``;

export const BottomWrapper = styled.div`
    text-align: center;
    padding-top: 64px;

    @media screen and (max-width: ${Viewport.Large}px) {
        padding-top: 32px;
    }
`;

export const Subtitle = styled.div`
    font-size: 14px;
    line-height: 1.4;
    padding-top: 16px;
    color: inherit;
    opacity: 50%;
`;

export const LogoWrapper = styled.div`
    max-width: 300px;
`;

export const SponsorWrapper = styled.div`
    max-width: 480px;

    @media screen and (max-width: ${Viewport.Large}px) {
        padding-bottom: 64px;
    }
`;

export const LinksWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 64px;

    @media screen and (max-width: ${Viewport.Small}px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 32px;
    }
`;

export const MenuItem = styled.li`
    list-style: none;
    padding: 4px 0;
    height: 24px;
`;

export const MenuLink = styled(Link)`
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: inherit;
    opacity: 50%;

    :hover {
        opacity: 80%;
    }
`;

export const MenuLinkStyledOverride = styled.a`
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: inherit;
    opacity: 50%;

    :hover {
        opacity: 80%;
    }
`;

export const LinkHeading = styled("h1")<IStyleProps>`
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 8px;
    color: inherit;
`;

export const SponsorshipLinkStyledOverride = styled.a`
    text-decoration: none;
    color: ${Color.Primary100};
    transition: opacity 0.1s;

    &:hover {
        color: ${Color.Primary110};
    }
`;
