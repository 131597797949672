import styled, { css, keyframes } from "styled-components";

interface IShimmerProps {
    readonly height: number;
    readonly width: number;
}

const shimmerKeyframes = keyframes`
   0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const blinkKeyframes = keyframes`
   0%{
        opacity:0
    }
    50%{
        opacity:1
    }
    100%{
        opacity:0
    }
`;

export const Shimmer = styled.div<IShimmerProps>`
    animation-name: ${shimmerKeyframes};
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`;
interface ILoadingProps {
    readonly isLoading: boolean;
}

export const StyledImage = styled.img<ILoadingProps>`
    cursor: pointer;
    opacity: 1;
    animation: ${({ isLoading }) =>
        isLoading === true
            ? css`
                  ${blinkKeyframes} 1s linear infinite
              `
            : "none"};
`;
