import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useJobOutput } from "../../../hooks/api/job/useJobOutput";
import { Shimmer, StyledImage } from "./FullsizeImage.styles";

interface IProps {
    readonly imageId: string;
    readonly jobId: string;
    readonly height: number;
    readonly width: number;
}

export const FullsizeImage: React.FC<IProps> = ({ imageId, jobId, width, height }) => {
    const jobOutputData = useJobOutput(jobId, imageId, true, { width, height });
    const fullSizeOutputData = useJobOutput(jobId, imageId, false);
    const [fullSizeImage, setFullSizeImage] = useState<string | null>(null);

    const openFullSizeImage = useCallback(() => {
        if (fullSizeImage !== null) {
            const image = new Image();
            image.src = fullSizeImage;

            const w = window.open("");
            if (w !== null) {
                w.document.write(image.outerHTML);
            }
        }
    }, [fullSizeImage]);
    const onClick = useCallback(() => {
        if (fullSizeImage === null) {
            fullSizeOutputData.refetch();
            return;
        }
        openFullSizeImage();
    }, [fullSizeOutputData.refetch, openFullSizeImage]);

    const isFullSizeLoading = fullSizeOutputData.fetchStatus === "fetching";

    useEffect(() => {
        if (fullSizeOutputData.data !== undefined) {
            setFullSizeImage(fullSizeOutputData.data);
        }
    }, [fullSizeOutputData.data, setFullSizeImage]);

    useEffect(() => {
        if (fullSizeImage !== null) {
            openFullSizeImage();
        }
    }, [fullSizeImage, openFullSizeImage]);

    if (jobOutputData.isLoading) {
        return <Shimmer width={width} height={height} />;
    }
    return (
        <StyledImage width={width} height={height} src={jobOutputData.data ?? undefined} onClick={onClick} isLoading={isFullSizeLoading} />
    );
};
