import { PageProps } from "gatsby";
import React from "react";
import { ImageDetailsPage } from "../../../../components/pages/ImageDetailsPage/ImageDetailsPage";

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ params }) => {
    const imageId = params?.imageId ?? "";
    const jobId = params?.jobId ?? "";
    return <ImageDetailsPage imageId={imageId} jobId={jobId} />;
};
export default Page;
